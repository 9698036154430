<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <h3 v-if="resultData.id">Редактирование результата</h3>
                <h3 v-else>Создание результата</h3>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="handleSubmit">
                  <CInput
                    v-model="resultData.lead"
                    label="Название результата"
                    placeholder=""
                    @input="requestQuizResultUpdate"
                  >
                  </CInput>
                  <div v-if="resultData.id">
                    <CTextarea
                      rows="6"
                      v-model="resultData.text"
                      label="Текст результата"
                      placeholder=""
                      @input="requestQuizResultUpdate"
                    >
                    </CTextarea>
                    <CSelect
                      :value.sync="resultData.person_type"
                      label="Тип личности"
                      :options="personTypes"
                      @change="requestQuizResultUpdate"
                    />
                    <CSelect
                      :value.sync="resultData.zodiac_sign"
                      label="Знак зодиака"
                      :options="zodiacSigns"
                      @change="requestQuizResultUpdate"
                    />
                    <CInputFile
                      label="Видео результата"
                      placeholder=""
                      @change="uploadQuizResultImage"
                    >
                    </CInputFile>
                    <div v-if="image">
                      <video
                        width="320"
                        autoplay
                        loop
                        muted
                        playsinline
                        style="pointer-events: none"
                      >
                        <source :src="image" type="video/mp4" />
                      </video>
                    </div>
                    <br />
                    <br />
                    <br />
                    <CInputFile
                      label="Постер результата"
                      placeholder=""
                      @change="uploadQuizPoster"
                    >
                    </CInputFile>
                    <div v-if="poster">
                      <img style="max-width: 400px" :src="poster" />
                    </div>
                    <br />
                    <br />
                    <br />
                    <CInputFile
                      label="Картинка шера результата"
                      placeholder=""
                      @change="uploadQuizResultShareImage"
                    >
                    </CInputFile>
                    <div v-if="shareImage">
                      <img style="max-width: 400px" :src="shareImage" />
                    </div>
                    <br />
                  </div>
                  <CRow>
                    <CCol>
                      <CButton
                        v-if="resultData.id"
                        color="success"
                        type="submit"
                      >
                        Сохранить
                      </CButton>
                      <CButton v-else color="success" type="submit">
                        Создать
                      </CButton>
                    </CCol>
                    <CCol>
                      <CButton
                        v-if="resultData.id"
                        color="success"
                        type="button"
                        @click="
                          router.push({
                            name: 'result-create',
                            params: { quizId: resultData.quizId },
                          })
                        "
                      >
                        Добавить ещё
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { makeCoreUICSelectList } from "../helpers";
import { debounce } from "lodash";
import { router } from "../router";
export default {
  name: "QuizResultForm",
  data() {
    return {
      image: null,
      poster: null,
      shareImage: null,
      router,
      personTypes: [],
      zodiacSigns: [],
      resultData: {
        id: this.$route.params.id,
        quizId: this.$route.params.quizId,
        lead: null,
        image: null,
        share_image: null,
        text: null,
      },
    };
  },
  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "zodiacSigns/getAllSuccess") {
        this.zodiacSigns = makeCoreUICSelectList(
          "name",
          "id",
          mutation.payload
        );
      } else if (mutation.type === "personTypes/getAllSuccess") {
        this.personTypes = makeCoreUICSelectList(
          "name",
          "id",
          mutation.payload
        );
      } else if (mutation.type === "results/getByIdSuccess") {
        this.initQuizResultForm(mutation.payload);
      } else if (mutation.type === "results/createSuccess") {
        this.initQuizResultForm(mutation.payload);
        router.push({
          name: "result-edit",
          params: { quizId: this.resultData.quizId, id: this.resultData.id },
        });
      }
    });
    if (this.resultData.id) {
      this["results/getById"]({
        quizId: this.resultData.quizId,
        id: this.resultData.id,
      });
    }
    this["zodiacSigns/getAll"]();
    this["personTypes/getAll"]();
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions([
      "results/create",
      "results/update",
      "results/getById",
      "results/saveImage",
      "results/savePoster",
      "results/saveShareImage",
      "zodiacSigns/getAll",
      "personTypes/getAll",
    ]),
    requestQuizResultUpdate: debounce(function () {
      this.updateQuizResult();
    }, 500),

    initQuizResultForm(data) {
      this.resultData.id = data.id;
      this.resultData.lead = data.lead;
      this.resultData.image = data.image;
      this.resultData.share_image = data.share_image;
      this.resultData.text = data.text;
      this.resultData.person_type = data.person_type;
      this.resultData.zodiac_sign = data.zodiac_sign;
      this.image = data.image;
      this.poster = data.poster;
      this.shareImage = data.share_image;
    },

    updateQuizResult() {
      let method;
      if (this.resultData.id) {
        method = "results/update";
      } else {
        method = "results/create";
      }
      const dataToSend = Object.assign({}, this.resultData);
      delete dataToSend.image;
      delete dataToSend.share_image;
      if (dataToSend.person_type === 0) {
        dataToSend.person_type = null;
      }
      if (dataToSend.zodiac_sign === 0) {
        dataToSend.zodiac_sign = null;
      }
      this[method](dataToSend);
    },
    uploadQuizResultImage(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.image = reader.result;
          saveThis["results/saveImage"]({
            quizId: saveThis.resultData.quizId,
            id: saveThis.resultData.id,
            dataUrl: saveThis.image,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },
    uploadQuizResultImage(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.image = reader.result;
          saveThis["results/saveImage"]({
            quizId: saveThis.resultData.quizId,
            id: saveThis.resultData.id,
            dataUrl: saveThis.image,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },
    uploadQuizPoster(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.poster = reader.result;
          saveThis["results/savePoster"]({
            quizId: saveThis.resultData.quizId,
            id: saveThis.resultData.id,
            dataUrl: saveThis.poster,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },
    uploadQuizResultShareImage(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.shareImage = reader.result;
          saveThis["results/saveShareImage"]({
            quizId: saveThis.resultData.quizId,
            id: saveThis.resultData.id,
            dataUrl: saveThis.shareImage,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },
  },
};
</script>
